import React from 'react'
import styled from 'styled-components'
import IconCheckmark from '../images/vector/icon-checkmark.svg'
import IconRestricted from '../images/vector/icon-restricted.svg'
import { Flex, Box } from 'reflexbox'

const StyledCallout = styled(Flex)`
  > div {
    display: inline-block;
    vertical-align: middle;

    &:first-child {
      min-width: 24px;
      margin-right: 0.75rem;
    }

    &:last-child {
      margin-top: 2px;
    }
  }

  p,
  a {
    margin-bottom: 0;
  }
`

const Callout = props => {
  return (
    <StyledCallout {...props}>
      <Box>{!props.restricted ? <IconCheckmark /> : <IconRestricted />}</Box>
      <Box>
        <p>{props.children}</p>
      </Box>
    </StyledCallout>
  )
}

export default Callout
