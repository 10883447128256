import React from 'react'
import { Box } from 'reflexbox'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Callout from '../components/Callout'

const TermsOfUse = props => {
  function optOut() {
    localStorage.setItem('optOut', true)
    localStorage.setItem('cookieConsent', true)
    window.location.reload()
  }

  return (
    <>
      <SEO title="Privacy policy" />

      {/* Hero */}
      <Container my={[4, 5]}>
        <Box textAlign={'center'}>
          <h1>Privacy policy</h1>
          <p className="subtitle">
            Everything you need to know about how we process your data
          </p>
        </Box>
      </Container>

      {/* Content */}
      <Container mb={[4, 5]}>
        <Box mx="auto" width={[1, 3 / 5]}>
          <Box mb={[4, 5]}>
            <p>
              We treat your data as if it were our own, so we only access or
              share your data to provide you with good customer service. This
              requires us to track website behaviour, store cookies, and process
              your information.
            </p>
          </Box>
          <Box mb={[3, 4]}>
            <h4>
              Your data <u>is</u> used to:
            </h4>
            <Callout mb={2}>Process your online payment(s)</Callout>
            <Callout mb={2}>
              Send you your order confirmation and license key(s)
            </Callout>
            <Callout mb={2}>Offer customer support</Callout>
            <Callout mb={2}>
              Send you product updates or promotions by email (if opted in)
            </Callout>
          </Box>
          <Box mb={[4, 5]}>
            <h4>You have the rights to:</h4>
            <Callout mb={2}>
              <a href="#cookies">Opt-out of tracking cookies</a> used on our
              site.
            </Callout>
          </Box>
          <Box mb={[3, 4]}>
            <h2>Services we use</h2>
            <p>
              We don't share any personally identifying information publicly or
              with third-parties, except when required to by law.
            </p>
            <p>
              We default to processing your data anonymously, but this isn’t
              possible for all the services we use. Exceptions of this are:
            </p>
          </Box>

          <Box mb={[4, 5]}>
            <h5>Paddle</h5>
            <p>
              We use Paddle to process payments and send you your order
              confirmation and delivery information. Paddle processes your
              contact and payment details. Your contact details are accessible
              by us, but payment details will never be disclosed with us by
              Paddle. <br /> <br />
              Further reading:{' '}
              <a href="https://paddle.com/privacy-buyers/" rel="nofollow">
                Buyer Privacy Notice
              </a>
            </p>
            <h5>Fastmail</h5>
            <p>
              We use Fastmail to host emails sent to info@geticonjar.com. <br />
              <br />
              Further reading:{' '}
              <a href="https://www.fastmail.com/blog/gdpr/" rel="nofollow">
                GDPR: European Data Protection
              </a>
            </p>
          </Box>
          <Box mb={[4, 5]} id="cookies">
            <h2>Cookies</h2>
            <p>
              Our website uses cookies to provide you the best possible browsing
              experience. Cookies are enabled by default, but you're free to
              opt-out, and we'll remove the cookies from your machine
              accordingly.{' '}
              <a href="javascript:gaOptout();" onClick={optOut}>
                Click here to opt-out.
              </a>{' '}
            </p>
            <ul>
              <li>
                <p>
                  <strong>Paddle</strong> is used to provide you with a way to
                  purchase a subscription for IconJar.
                </p>
              </li>
            </ul>
          </Box>
          <Box mb={[4, 5]}>
            <h2>View or deleting your data</h2>
            <p>
              Under the law of GDPR, you always have the right to request the
              data we have about you.{' '}
            </p>
            <p>
              In case you wish your data to be deleted from the services, we
              can’t guarantee that you’ll still be able to use IconJar properly
              or whether we can be of any help if you request support.{' '}
            </p>
            <p>
              To request viewing or deleting your data you can send an email to{' '}
              <a href="mailto:info@geticonjar.com">info@geticonjar.com</a>.
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h2>Correction of data</h2>
            <p>
              If for any reason the data we have on you needs to be corrected
              you can send an email to{' '}
              <a href="mailto:info@geticonjar.com">info@geticonjar.com</a>.
            </p>
          </Box>
          <Box mb={[5, 6]}>
            <h2>Questions?</h2>
            <p>
              Feel free to email us if you have any questions regarding the
              contents of this privacy policy. You can reach us on{' '}
              <a href="mailto:info@geticonjar.com">info@geticonjar.com</a>.
            </p>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default TermsOfUse
